<template>
  <b-card
      title="设备管理"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="设备状态"
              label-for="input-lg"
          >
            <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="queryParams.deviceStatus"
                :options="deviceStatusOptions"
                v-model="queryParams.deviceStatus"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:roleFilter', val)"
                placeholder="请选择设备状态"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="设备ID"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder="请输入设备ID"
                v-model="queryParams.deviceId"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label-size="lg"
              label="产品名称"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder="请输入产品名称"
                v-model="queryParams.productName"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="标签名称"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder="请输入标签名称"
                v-model="queryParams.tagName"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
  <!--          <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label-size="lg"
                label="机构"
                label-for="input-lg"
            >
              <b-form-input
                  id="input-lg"
                  placeholder=""
              />
          </b-form-group>-->
        </b-col>
        <b-col md="2" class="tRight">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"  @click="searchButtonClick">
            搜索
          </b-button>
        </b-col>
      </b-row>
      <b-button
          class="mb-2 mr-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-modal.modal-relation
      >
        设置分组
      </b-button>
      <b-button
          class="mb-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="clearTenantPath"
      >
        清除分组
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :sort-options="{
        enabled: false,
      }"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:queryParams.pageSize
      }"
        theme="my-theme"
        @on-selected-rows-change="onSelectChange"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'deviceStatus'" class="text-nowrap">
          <span>{{ formatterDeviceStatus(props.row.deviceStatus) }}</span>
        </span>
<!--        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1"/>
          <span>{{ props.row.fullName }}</span>
        </span>-->
        <!-- Column: Action -->
<!--        <span v-else-if="props.column.field === 'label'">
          <b-button v-for="(item,i) in labelList" :key="i" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="btn-sm mr-50 mb-50" @click="del(item,i)" badge="4">
              {{ item.name }}
              <feather-icon icon="XIcon" size="12"/>
          </b-button>
        </span>-->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="tCenter">
          <b-pagination
              v-model="queryParams.currentPage"
              :total-rows="queryParams.total"
              :per-page="queryParams.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="currentPageHandleChange"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="modal-relation" title="修改分组" centered ok-only ok-title="确定" @ok="setTenantPath">
      <div>
        <el-tree ref="menuTree" :props="props" :data="groups" node-key="id" :check-strictly="true" highlight-current show-checkbox default-expand-all :expand-on-click-node="false" @node-click="nodeClick" @check-change="handleCheckChange">
          <span class="custom-tree-node" slot-scope="{ node, data }">
              <span  >{{ data.name }}</span>
            </span>
        </el-tree>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      roleFilter: null,
      columns: [
        {
          label: '设备ID',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        {
          label: '设备状态',
          field: 'deviceStatus',
        },
        {
          label: '位置',
          field: 'pathStr',
        },
      ],
      rows: [],
      searchTerm: '',
      labelList: [{
        id: 1,
        name: '标签1'
      }, {
        id: 2,
        name: '标签2'
      }],
      dir: 'ltr',

      props: {
        label: 'name',
        children: 'childNodes',
      },

      count: 1,

      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: null,
        productName: null,
        deviceStatus: null,
        deviceId: null,
        tagName:null,
      },

      deviceStatusOptions: [
        {
          value: 0,
          label: '未激活'
        }
      ],

      groups: [],

      chooseGroup:null,

      selectRows: [],
      selectRowIds: [],

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

  },
  created() {
    this.groupsData();
    this.getTableList();
    this.deviceStatusOptionsFormat();
  },
  methods: {

    getTableList() {
      request.post('tob/tenant/tenantGroup/pageDeviceConfig', {
        pageSize: this.queryParams.pageSize,
        currentPage: this.queryParams.currentPage,
        productName: this.queryParams.productName,
        deviceStatus: this.queryParams.deviceStatus,
        deviceId: this.queryParams.deviceId,
        tagName: this.queryParams.tagName,
      }).then(res => {
        if (res.data.success) {
          this.rows = res.data.data.data
          this.queryParams.total = res.data.data.count
        }
      })
    },

    groupsData() {
      request.get('tob/tenant/tenantGroup/listTenantGroupsById', {
      }).then(res => {
        console.log("res",res);
        if (res.data.success) {
          this.groups = res.data.data;
        }
      })
    },

    deviceStatusOptionsFormat() {
      request.get('tob/bDevice/getDeviceStatusEnums', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.deviceStatusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.deviceStatusOptions.push(enumObject);
          }
        }
      })
    },

    formatterDeviceStatus(val) {
      for (let item of this.deviceStatusOptions) {
        if (item.value === val) {
          return item.label
        }
      }
      return val
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.getTableList()
    },

    searchButtonClick() {
      this.queryParams.currentPage = 1
      this.getTableList()
    },

    nodeClick(data, node, v) {
      this.chooseGroup = data.id
      this.$refs.menuTree.setCheckedKeys([data.id]);
    },

    handleCheckChange(item,checked,self){
      if(checked){
        this.chooseGroup = item.id
        this.$refs.menuTree.setCheckedKeys([item.id]);
      }else{
        if(this.chooseGroup === item.id){
          this.$refs.menuTree.setCheckedKeys([item.id]);
        }
      }
    },

    onSelectChange(params) {
      this.selectRows = params.selectedRows;
      this.selectRowIds = [];
      for (const argument of this.selectRows) {
        this.selectRowIds.push(argument.deviceConfigId);
      }
    },

    clearTenantPath(){
      if (this.selectRowIds==undefined || this.selectRowIds.length<=0){
        this.$message.error('请先选择设备') ;
        return ;
      }
      request.post('tob/tenant/tenantGroup/clearTenantPath', {deviceConfigIds:this.selectRowIds}).then(res => {
        if (res.data.success) {
          this.$message.success('设置成功')
          this.getTableList();
        }
      })
    },

    setTenantPath(params){
      if (this.selectRowIds==undefined || this.selectRowIds.length<=0){
        this.$message.error('请先选择设备') ;
        return ;
      }

      params.preventDefault();//禁止关闭
      if (this.chooseGroup == null||this.chooseGroup == undefined||this.chooseGroup<=0){
        this.$message.error('请选择分组') ;
        return ;
      }

      request.post('tob/tenant/tenantGroup/setTenantPath', {tenantGroupId:this.chooseGroup,deviceConfigIds:this.selectRowIds}).then(res => {
        if (res.data.success) {
          this.$message.success('设置成功')
          this.$nextTick(() => {//关闭
            this.$bvModal.hide('modal-relation')
          })
          this.getTableList();
          this.chooseGroup=null;
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
